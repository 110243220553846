import React, { useState, useEffect } from "react";
import Unity, { UnityContext } from "react-unity-webgl";

const unityContext = new UnityContext({
  loaderUrl: "Build/betaBuild.loader.js",
  dataUrl: "Build/betaBuild.data",
  frameworkUrl: "Build/betaBuild.framework.js",
  codeUrl: "Build/betaBuild.wasm",
});

function App() {
  const [progression, setProgression] = useState(0);

  useEffect(function () {
    unityContext.on("progress",  (progression) => {
      setProgression(progression);
    });
  }, []);
  useEffect(() => {
    var loadingBar = document.getElementById("unity-loading-bar");
    var progressBarFull = document.getElementById("unity-progress-bar-full");
    loadingBar.style.display = "block";
    progressBarFull.style.width = 100 * progression * (95/100) + "%";
  },[progression])

  useEffect(() => {
    if(progression == 1){
      setTimeout(()=>{
        var logo = document.getElementById("xcodelogo");
        var loadingBar = document.getElementById("unity-loading-bar");
        var progressBarFull = document.getElementById("unity-progress-bar-full");
        loadingBar.style.display = "block";
        loadingBar.style.display = "none";
        progressBarFull.style.width = 100;
        logo.hidden = true;
      },2000)
    }
  },[progression])

  function handlerFunc() {
    if(window.getCon){
      unityContext.send("SessionHandler", "metaMask");
      window.getCon = false;
    }
  }

  useEffect(() => {
    window.addEventListener("message",handlerFunc);
    return () => window.removeEventListener("message",handlerFunc);
  })

  return (
    <>
      <div id="unity-container" class="unity-desktop">
        <Unity unityContext={unityContext} style={{"height":"92vh","aspectRatio":"9/16", position:"fixed", margin: "auto", left: 0, right: 0,"marginTop":"4vh !important"}}>
        </Unity>
        <div id="xcodelogo" style={{"height":"92vh","aspectRatio":"9/16", position:"fixed", margin: "auto", left: 0, right: 0,"marginTop":"4vh !important", backgroundColor:"black"}}>
          <img style={{"display": "block","margin-left": "auto","margin-right": "auto","width": "320px", marginTop:"60%"}} src="xcodelogo.png"></img>
          <img style={{"display": "block","margin-left": "auto","margin-right": "auto","width": "190px", marginTop:"45%"}} src="poweredby.png"></img>
          <img style={{"display": "block","margin-left": "auto","margin-right": "auto","width": "280px", marginTop:"-22%"}} src="polygonstudios-logo.png"></img>
        </div>
        <div id="unity-loading-bar">
          <div id="unity-logo" ></div>
          <div id="unity-progress-bar-empty">
            <div id="unity-progress-bar-full"></div>
          </div>
        </div>
        <div id="unity-warning"> </div>
        
      </div>
    </>
  );
}

export default App;
